/* StickyButtons.css */

.sticky-buttons {
  position: fixed;
  bottom: 40px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
}

.phone-button,
.mail-button {
  color: green;
  border: none;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
}

.phone-button:hover,
.mail-button:hover {
  color: rgb(0, 66, 0);
}
