.app-container {
    position: relative;
  }
  
  .app-container::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../src/assets/other/bg-texture.jpg');
    opacity: 0.2;
    z-index: -1;
  }